import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BsFileEarmarkRichtext } from "react-icons/bs";
import { HiOutlineSave } from "react-icons/hi";
import { FiCopy } from "react-icons/fi";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import slugify from "slugify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";

ChartJS.register(ArcElement, Tooltip, Legend);

import api from "../../services/api";
import "../../styles/results.css";

//Components

import HeaderResults from "../../components/results/HeaderResults";
import ActionPlanSection from "../../components/results/ActionPlanSection";
import { TreemapSection } from "../../components/results/ResultsPartials";

import MyIcon from "../../components/partials/MyIcon";
import Header from "../../components/partials/Header";

//Modals
import ModalCompensation from "components/results/ModalCompensation";

//Utils
import { Loader } from "../../components/loader";
import { referencingInfos } from "../../utils/config";

import useStore from "Stores/zustand";

import INFOS from "./infos.json";
import _exports from "tailwind-scrollbar";
import { createCncXlsxExport } from "./utils";

const Results = (props) => {
  const [estimation, setEstimation] = useState(null);
  const [exportSynthesis, setExportSynthesis] = useState(false);
  const { estimationId } = useParams();
  const { user } = useStore();
  const queryParameters = new URLSearchParams(window.location.search);

  //MODALS
  const [isCompensationOpen, setIsCompensationOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/estimations/${estimationId}`);
      if (response.ok) setEstimation(response.data);
    }
    fetchData();
    if (queryParameters.get("dl") === "true") setExportSynthesis(true);
  }, []);

  const handlePdfExport = async () => {
    const doc = new jsPDF("p", "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const sections = [
      "header-infos",
      "resultats-generaux",
      "treemap-section",
      ...estimation.form.map((category) => slugify(category.name.toLowerCase())),
      ...RecommendationsInfos.map((_, i) => `recommendation-${i}-auto-paging`),
      "additional-infos-auto-paging",
    ];

    const options = {
      scale: 2, // You may adjust this value to control the quality and size of the captured content
      useCORS: true, // Enable this if you need to capture content from external domains
    };

    /*
    Got this warning in the console:
    document-cloner.ts:226 Canvas2D: Multiple readback operations using getImageData are faster with the willReadFrequently attribute set to true. 
    See: https://html.spec.whatwg.org/multipage/canvas.html#concept-canvas-will-read-frequently

    --> To check afterwards
    */
    let top;
    const padding = 15;

    for (let i = 0; i < sections.length; i++) {
      const content = document.getElementById(sections[i]);
      if (!content) return;
      const isComplexToConvert = sections[i] === "treemap-section";
      const elemsToHide = content.querySelectorAll(".to_not_print");
      const elemsToDisplay = content.querySelectorAll(".to_print");

      for (const elem of elemsToHide) {
        elem.style["display"] = "none";
      }
      for (const elem of elemsToDisplay) {
        elem.style["display"] = "block";
      }

      let elHeight = content.offsetHeight;
      let elWidth = content.offsetWidth;
      if (elWidth > pageWidth) {
        const ratio = Math.floor((pageWidth / elWidth) * 100) / 100;
        //resize chart width and heigth proportionally
        elHeight = Math.floor(elHeight * ratio - padding * 0.4);
        elWidth = Math.floor(elWidth * ratio - padding * 2);
      }

      if ((!sections[i].includes("auto-paging") && i > 0) || top + elHeight > pageHeight) {
        doc.addPage();
        top = 20;
      }

      if (i === 0) top = pageHeight / 3;

      let img;
      if (isComplexToConvert) {
        img = await htmlToImage.toPng(content);
      } else {
        const canvas = await html2canvas(content, options);
        img = canvas.toDataURL("image/jpeg");
      }

      doc.addImage(img, isComplexToConvert ? "PNG" : "JPEG", padding, top, elWidth, elHeight, undefined, "FAST");

      top += elHeight + 10;
      for (const elem of elemsToHide) {
        elem.style["display"] = "block";
      }
      for (const elem of elemsToDisplay) {
        elem.style["display"] = "none";
      }
    }

    doc.save(`Seco2 - ${estimation.information.title} - Impact CO2, résultats détaillées de mon estimation carbone`);
  };

  const handleCncExport = async () => {
    const buffer = await createCncXlsxExport(estimation);
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Seco2_Bilan_${estimation.information.title}.xlsx`;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  if (!estimation) return <Loader fullPage />;

  if (!estimation.validated && props.history?.action === "POP") {
    return <Navigate to="/dashboard" />;
  }

  const title = estimation.information.title;

  const totalImpact = estimation.form.reduce((total, category) => {
    return total + category.impact;
  }, 0);

  const pageTitle = `Seco2 - ${title} - Impact CO2, résultats détaillées de mon estimation carbone`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ModalCompensation isOpen={isCompensationOpen} close={() => setIsCompensationOpen(false)} title={title} user={user} totalEmissions={totalImpact} />
      <Header>
        <HeaderResults title={title} />
      </Header>
      <div className="w-full h-full flex items-start gap-3 p-4 bg-backgrounds">
        <nav className="w-1/4">
          <Nav estimation={estimation} onPdfExport={handlePdfExport} handleCncExport={handleCncExport} exportOnLoad={queryParameters.get("dl") === "true"} />
        </nav>
        <div className="w-full p-8 flex flex-col bg-white">
          <section id="header-infos" className="flex flex-col items-center">
            <HeaderInfos estimation={estimation} />
          </section>
          <section id="resultats-generaux" className="mt-6">
            <GeneralResults estimation={estimation} />
          </section>
          <section id="treemap-section" className="mt-6 bg-white">
            <TreemapSection className="padding_for_print pt-10" title="Détails de la répartition de vos émissions" data={estimation.form} />
          </section>
          <section id="action-plan" className="mt-6">
            <ActionPlanSection estimation={estimation} openCompensation={() => setIsCompensationOpen(true)} />
          </section>

          {estimation.form.map((category, i) => (
            <section key={i} id={slugify(category.name.toLowerCase())} className="mt-6">
              <CategoryResults category={category} index={i + 1} />
            </section>
          ))}

          {RecommendationsInfos.map((recommendation, i) => (
            <section key={i} id={`recommendation-${i}-auto-paging`}>
              {recommendation}
            </section>
          ))}

          <div id="additional-infos-auto-paging" className="mt-6">
            <AdditionalInfos estimation={estimation} />
          </div>
        </div>
      </div>
    </>
  );
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const getTotalAmount = (categories) => {
  if (!categories || categories.length === 0) return 0;
  return categories.reduce((total, category) => total + category.amount, 0);
};

const getTotalImpact = (categories) => {
  if (!categories || categories.length === 0) return 0;
  return categories.reduce((total, category) => total + category.impact, 0);
};

const getUnit = (value, units) => {
  if (value === 0) return { number: 0, unit: units[0] };
  const log10 = Math.floor(Math.log10(value));
  const index = Math.min(Math.floor(log10 / 3), units.length - 1);
  const unit = units[index];
  const number = value / Math.pow(10, index * 3);
  return { number, unit };
};

const getCO2Equivalence = (value) => {
  if (value > 1000) {
    return {
      value: value / 1000 / 1.77,
      unit: "",
      description: "vols A/R Paris / New York",
    };
  } else {
    return {
      value: value * 5.181,
      unit: "kms",
      description: "en voiture (moyenne)",
    };
  }
};

const BILAN_LABEL = {
  definitif: "Bilan carbone définitif",
  Prévisionnel: "Bilan carbone prévisionnel",
};

const HeaderInfos = ({ estimation }) => (
  <>
    <div className="mb-8 w-32 h-10 flex items-center justify-center">
      <img src="/images/logos/Logo_seco2_bleu.png" alt="logo secoya" className="max-w-full max-h-full" />
    </div>

    <div className="mx-auto text-center">
      <h4 className="text-2xl text-blue-dark font-bold">{estimation.information.title}</h4>
      <p className="leading-6 font-semibold text-blue-dark mb-5">{`${BILAN_LABEL[estimation.information.type]} généré le ${new Date(estimation.updatedAt).toLocaleDateString(
        "fr-FR",
      )}`}</p>
      <p className="w-9/12 mx-auto text-xs mb-5">
        Ce bilan carbone est réalisé à partir de vos données fournies associées aux facteurs d'émission de La Base Carbone®, base de données publiques administrée par l'ADEME.
      </p>
    </div>
    {estimation.uniqueId && (
      <>
        <div className="mx-auto text-center">
          <p className="font-bold text-xs leading-4">Méthode de calcul homologuée par le CNC</p>
        </div>
        <div className="flex items-center">
          <p className="text-sm text-center mr-2">Numéro d'identification CNC:</p>
          <button onClick={() => navigator.clipboard.writeText(estimation.uniqueId)} className="flex items-center pr-8">
            {estimation.uniqueId} <FiCopy className="ml-2 to_not_print" />
          </button>
        </div>
      </>
    )}
  </>
);

const PRODUCTIONS_SETTINGS = {
  "Long-Métrage": {
    icon: "cinema",
    name: "Cinéma",
    description: "Long-métrage",
  },
  "Court-Métrage": {
    icon: "cinema",
    name: "Cinéma",
    description: "Court-métrage",
  },
  Publicité: {
    icon: "ad",
    name: "Publicité",
    description: "Publicité",
  },
  Série: {
    icon: "tournage",
    name: "Cinéma",
    description: "Série",
  },
  Unitaire: {
    icon: "tournage",
    name: "Cinéma",
    description: "Unitaire",
  },
};

const GeneralResults = ({ estimation }) => {
  const amount = getUnit(getTotalAmount(estimation.form), ["€", "k€", "M€"]);
  const impact = getUnit(getTotalImpact(estimation.form), ["kgCO2eq", "tCO2eq"]);

  const equivalence = getCO2Equivalence(getTotalImpact(estimation.form));
  const settings = PRODUCTIONS_SETTINGS[estimation.information.productionType] || PRODUCTIONS_SETTINGS["Long-Métrage"];

  // Hide legend
  const options = { plugins: { legend: { display: false } } };
  const infos = [
    { title: "Titre", name: "title" },
    { title: "Type de bilan", name: "carbonFootprintType" },
    { title: "Format", name: "productionType" },
    { title: "Type d'oeuvre", name: "typeOfWork" },
    { title: "Genre", name: "genre" },
    { title: "Nom production", name: "productionName" },
    { title: "Lieu(x) de tournage", name: "shootingLocation" },
    { title: "Date de tournage", name: "shootingDate" },
    { title: "Budget", name: "budget" },
    { title: "Nombre de jours de tournage", name: "shootingDays" },
    { title: "Responsable estimation carbone", name: "carbonFootprintResponsible" },
  ];

  const getInfo = (estimation, name) => {
    if (!estimation) return "";
    const keys = Object.keys(estimation.information);
    const found = keys.findIndex((info) => info === name);
    return Object.values(estimation.information)[found];
  };

  const buildDonutData = (categories) => {
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };
    categories.forEach((category) => {
      data.labels.push(category.name);
      data.datasets[0].data.push(category.impact);
      data.datasets[0].backgroundColor.push(category.color);
      data.datasets[0].hoverBackgroundColor.push(category.color);
    });
    return data;
  };

  return (
    <>
      <div className="w-full flex items-center border-b border-blue-border pb-4 mb-6 gap-3">
        <p className="font-bold text-xl px-2 bg-back-light-blue">0.</p>
        <h2 className="font-bold text-xl">Informations générales du projet</h2>
      </div>
      {impact.number > 0 ? (
        <section className="grid grid-cols-4 items-center gap-5">
          <Badge icon={settings.icon} name={settings.name} description={settings.description} color="#cfdce3" />
          <Badge
            icon="co2"
            name={`${impact.number.toLocaleString("fr", { maximumFractionDigits: 0 })} ${impact.unit}`}
            description="Montant des émissions pour la catégorie"
            color="#cfdce3"
          />

          <Badge
            icon="budget"
            name={`${amount.number.toLocaleString("fr", { maximumFractionDigits: 0 })} ${amount.unit}`}
            description="Budget pris en compte pour la catégorie"
            color="#cfdce3"
          />

          <Badge
            icon="balance"
            name={`${equivalence.value.toLocaleString("fr", { maximumFractionDigits: 0 })} ${equivalence.unit}`}
            description={equivalence.description}
            subdescription="en équivalence carbone"
            color="#cfdce3"
          />
        </section>
      ) : (
        <section className="w-full">
          <Badge icon="co2" name="0 kgCO2eq" description="Montant des émissions pour la catégorie" color="#cfdce3" />
        </section>
      )}
      <div className="mt-5 w-full px-16 py-4 grid grid-cols-2">
        {infos
          .filter((i) => getInfo(estimation, i.name))
          .map((info, i) => (
            <div key={i} className="w-full flex items-center justify-center text-normal gap-8 mb-4">
              <p className="w-1/2 text-right font-normal">{info.title}</p>
              <p className="w-1/2 text-left font-bold text-base capitalize">{getInfo(estimation, info.name)}</p>
            </div>
          ))}
      </div>
      <p className="font-bold text-base leading-5 w-full flex items-center border-b border-blue-border pb-4 my-6">Chiffres clés de votre estimation carbone</p>
      <p className="mb-5">Classement des catégories par niveau d'émissions, par ordre décroissant.</p>
      <div className="mb-4 flex">
        <div className="w-1/3">
          <div className="flex items-center mb-4">
            <h3 className="w-[60%] font-semibold">Catégorie</h3>
            <h3 className="flex-1 font-semibold text-right">Émissions</h3>
          </div>
          {estimation.form
            .filter((category) => category.impact > 0)
            .sort((a, b) => b.impact - a.impact)
            .map((category, i) => (
              <div className="flex items-center mb-3" key={i}>
                <div className="w-[60%] flex items-center">
                  <div className="w-4 h-4 mr-3" style={{ backgroundColor: category.color }} />
                  <span className="flex-1 font-normal text-sm truncate">{category.name}</span>
                </div>
                <span className="flex-1 font-normal text-sm text-right">{category.impact.toLocaleString("fr", { maximumFractionDigits: 2 })} kgCO2eq</span>
              </div>
            ))}
        </div>

        <div className="flex-1 flex flex-col mb-6 items-center justify-center gap-5 h-64">
          <div className="p-6 h-56">
            <Doughnut data={buildDonutData(estimation.form)} options={options} />
          </div>
          <p className="font-normal text-center text-base">Répartition des émissions par postes du devis</p>
        </div>
      </div>
      <div className="grid grid-cols-4 items-center border border-blue-border p-4 gap-y-2 gap-x-4">
        {estimation.form
          .filter((category) => category.impact > 0)
          .map((category, i) => (
            <div key={i} className="flex items-center">
              <div className="w-4 h-4 rounded-sm mr-3" style={{ backgroundColor: category.color }} />
              <p className="font-normal text-xs">{category.name}</p>
            </div>
          ))}
      </div>
    </>
  );
};

const CategoryResults = ({ category, index }) => {
  const options = { plugins: { legend: { display: false } } };
  const amount = getUnit(category.amount, ["€", "k€", "M€"]);
  const impact = getUnit(category.impact, ["kgCO2eq", "tCO2eq"]);
  const equivalence = getCO2Equivalence(category.impact);

  const buildDonutData = (subCategories) => {
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };
    subCategories.forEach((category) => {
      data.labels.push(category.name);
      data.datasets[0].data.push(category.impact);
      data.datasets[0].backgroundColor.push(category.color);
      data.datasets[0].hoverBackgroundColor.push(category.color);
    });
    return data;
  };
  return (
    <>
      <div className="w-full flex items-center border-b border-blue-border pb-4 mb-6 gap-3">
        <p className="font-bold text-xl px-2 leading-7" style={{ backgroundColor: category.color }}>
          {index}.
        </p>
        <h2 className="font-bold text-xl leading-7">
          Détails du Poste {index}: {category.name}
        </h2>
      </div>

      {impact.number > 0 ? (
        <section className="grid grid-cols-3 items-center gap-5">
          <Badge
            icon="co2"
            name={`${impact.number.toLocaleString("fr", { maximumFractionDigits: 0 }) || 0} ${impact.unit}`}
            description="Montant des émissions pour la catégorie"
            color={category.color}
          />
          {amount.number > 0 && (
            <Badge
              icon="budget"
              name={`${amount.number?.toLocaleString("fr", { maximumFractionDigits: 0 }) || 0} ${amount.unit}`}
              description="Budget pris en compte pour la catégorie"
              color={category.color}
            />
          )}
          <Badge
            icon="balance"
            name={`${equivalence.value.toLocaleString("fr", { maximumFractionDigits: 0 })} ${equivalence.unit}`}
            description={equivalence.description}
            subdescription="en équivalence carbone"
            color={category.color}
          />
        </section>
      ) : (
        <section className="w-full">
          <Badge icon="co2" name="0 kgCO2eq" description="Montant des émissions pour la catégorie" color={category.color} />
        </section>
      )}

      {category.impact > 0 && category.subCategories && category.subCategories.length > 0 && (
        <>
          <p className="font-bold text-base leading-5 w-full flex items-center border-b border-blue-border pb-4 my-6">Chiffres clés de votre estimation carbone</p>
          <p className="mb-5">Classement des catégories par niveau d'émissions, par ordre décroissant.</p>
          <div className="mb-4 flex">
            <div className="w-1/3">
              <div className="flex items-center mb-4">
                <h3 className="w-[60%] font-semibold">Catégorie</h3>
                <h3 className="flex-1 font-semibold text-right">Émissions</h3>
              </div>
              {category.subCategories
                .filter((category) => category.impact > 0)
                .sort((a, b) => b.impact - a.impact)
                .map((category, i) => (
                  <div className="flex items-center mb-3" key={i}>
                    <div className="w-[60%] flex items-center">
                      <div className="w-4 h-4 mr-3" style={{ backgroundColor: category.color }} />
                      <span className="flex-1 font-normal text-sm truncate">{category.name}</span>
                    </div>
                    <span className="flex-1 font-normal text-sm text-right">{category.impact.toLocaleString("fr", { maximumFractionDigits: 2 })} kgCO2eq</span>
                  </div>
                ))}
            </div>

            <div className="flex-1 flex flex-col mb-6 items-center justify-center gap-5 h-64">
              <div className="p-6 h-56">
                <Doughnut data={buildDonutData(category.subCategories)} options={options} />
              </div>
              <p className="font-normal text-center text-base">Répartition des émissions par postes du devis</p>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center border border-blue-border p-4 gap-y-2 gap-x-4">
            {category.subCategories
              .filter((category) => category.impact > 0)
              .map((category, i) => (
                <div key={i} className="flex items-center">
                  <div className="w-4 h-4 rounded-sm mr-3" style={{ backgroundColor: category.color }} />
                  <p className="font-normal text-xs">{category.name}</p>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};

const RecommendationsInfos = INFOS.recommendations.map((category, i) => (
  <>
    {!i && <h2 className="font-bold text-3xl leading-6 text-blue-dark mb-4">Recommandations</h2>}
    <div key={i} className="mb-4">
      <h3 className="font-bold text-lg leading-6 pl-8 text-blue-dark mb-4">{category.category}</h3>
      {category.items.map((data, idx) => (
        <article className="text-blue-dark mb-5" key={idx}>
          <h4 className="font-bold">{data.action}</h4>
          <p>{data.description}</p>
          {data.url !== "" && <a href={data.url}>{data.url}</a>}
        </article>
      ))}
    </div>
  </>
));

const AdditionalInfos = ({ estimation }) => {
  const impact = getUnit(getTotalImpact(estimation.form), ["kgCO2eq", "tCO2eq", "ktCO2eq"]);

  return (
    <>
      <h2 className="font-bold text-3xl leading-6 text-blue-dark mb-4">Information complémentaires</h2>
      <div className="mb-4">
        <h3 className="font-bold text-lg leading-6 pl-8 text-blue-dark mb-4">Usages de la synthèse</h3>
        {INFOS.additional.map((use, i) => (
          <p key={i}>{use}</p>
        ))}
      </div>
      <div className="mb-4">
        <h3 className="font-bold text-lg leading-6 pl-8 text-blue-dark mb-4">Compensation</h3>
        <p>
          L'impact carbone de la production de {estimation.information.title} est estimé à{" "}
          <b>
            {impact.number.toLocaleString("fr", { maximumFractionDigits: 2 })} {impact.unit}
          </b>
        </p>
        <p>Le prix de la tonne carbone proposé par OCO, en partenariat avec Secoya, est de 18€ HT/tonne sur le projet REDD+ (description ci-dessous).</p>
        <p>
          Le montant de la contribution carbone de {estimation.information.title} revient à{" "}
          <b>{((getTotalImpact(estimation.form) * 18) / 1000).toLocaleString("fr", { maximumFractionDigits: 0 })} € HT</b>
        </p>
      </div>
    </>
  );
};

const Badge = ({ icon, name, description, subdescription, color }) => {
  return (
    <div className="flex flex-col items-center p-4 h-full" style={{ backgroundColor: color }}>
      <div>
        <img src={`/images/icons/${icon}.svg`} alt={name} className="row-span-2 h-12" />
      </div>
      <p className="font-bold text-center py-2 text-lg">{name}</p>
      <p className="text-center">{description}</p>
      {subdescription && <span className="text-center text-xs leading-3 italic py-px px-3 mt-1">{subdescription}</span>}
    </div>
  );
};

const Nav = ({ estimation, onPdfExport, handleCncExport, exportOnLoad }) => {
  const location = useLocation();
  const [activeAnchor, setActiveAnchor] = useState(location.hash);
  const [pdfExporting, setPdfExporting] = useState(false);

  useEffect(() => {
    setActiveAnchor(location.hash.slice(1));
  }, [location]);

  useEffect(() => {
    const exportPdf = async () => {
      //to wait the end of the animation before use html2canvas
      await sleep(600);
      await handlePdfExport();
    };
    if (exportOnLoad) exportPdf();
  }, [exportOnLoad]);

  const handlePdfExport = async () => {
    setPdfExporting(true);
    await onPdfExport();
    setPdfExporting(false);
  };

  return (
    <div className="flex flex-col gap-3">
      <button className="h-14 blue-button flex items-center justify-center gap-2" onClick={handlePdfExport} disabled={pdfExporting}>
        {pdfExporting ? (
          <Loader />
        ) : (
          <>
            <BsFileEarmarkRichtext />
            Télécharger une synthèse
          </>
        )}
      </button>
      {(estimation.information.carbonFootprintType === "definitif" || estimation.information.carbonFootprintType === "Prévisionnel") && (
        <button className="h-14 orange-button flex items-center justify-center gap-2" onClick={handleCncExport}>
          <HiOutlineSave />
          Exporter au format CNC
        </button>
      )}
      <Link to={`/estimation/${estimation._id}`} className="h-14 empty-button flex items-center justify-center gap-2">
        <MyIcon icon="edit" />
        Modifier les données
      </Link>
      <a href="#action-plan" className="h-14 orange-button p-2 items-center flex justify-center">
        Réduire mes émissions
      </a>

      <div className="bg-white rounded overflow-hidden">
        <p className="items-center h-8 text-base leading-8 pl-4 w-full font-bold bg-back-light-blue text-blue-dark">Accès rapide</p>
        <div className="flex flex-col px-6 divide-y divide-blue-border">
          <a href="#resultats-generaux" className="py-6 flex items-center hover:font-bold">
            <div className="w-[10%] mr-3">
              <p className="w-6 text-center bg-blue-gray">0.</p>
            </div>
            <p>Résultats Généraux</p>
          </a>
          {estimation.form.map((category, i) => {
            const anchor = slugify(category.name.toLowerCase());
            return (
              <a key={i} href={`#${anchor}`} className="py-6 flex items-center hover:font-bold">
                <div
                  className={`w-[10%] mr-3 ${
                    activeAnchor === anchor ? "font-bold before:w-2 before:h-6 before:bg-blue-dark before:p-1 before:rounded-sm before:absolute before:ml-[-20px]" : ""
                  }`}>
                  <p style={{ background: category.color }} className="w-6 text-center">
                    {i + 1}.
                  </p>
                </div>
                <p className={`flex-1 truncate ${activeAnchor === anchor ? "font-bold" : ""}`}>{category.name}</p>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Results;

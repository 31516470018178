import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import jsPDF from "jspdf";
import { BsFileEarmarkRichtext } from "react-icons/bs";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

import { Loader } from "components/loader";

import DistributionHistogram from "./DistributionHistogram";
import Treemap from "./Treemap";

import api from "services/api";
import { formatNumber } from "utils";

import useStore from "Stores/zustand";
import { Helmet } from "react-helmet";

const ESTIMATION_TYPES = [
  { name: "cnc", label: "CNC", color: "#cfdce3" },
  { name: "chedar", label: "Chedar", color: "#cfdce3" },
  { name: "alpha", label: "Alpha", color: "#cfdce3" },
  { name: "definitif", label: "Définitif", color: "#cfdce3" },
  { name: "Prévisionnel", label: "Prévisionnel", color: "#cfdce3" },
];

const Stats = () => {
  const [estimations, setEstimations] = useState(null);
  const { user } = useStore();

  useEffect(() => {
    api
      .get("/estimations/all/est")
      .then((res) => {
        if (res.ok) {
          let data = res.data;
          if (!user.hasPaid) data = data.filter((e) => e.hasPaid === true && e.userId === user._id);
          setEstimations(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!user?.hasPaid && !user?.hasABilanPaid) {
    return <Navigate to="/dashboard" />;
  }

  if (estimations && estimations.length === 0) {
    return <p>Aucun de vos utilisateurs n'a validé d'estimation. Encouragez les à débuter !</p>;
  }

  if (!estimations) return <Loader />;
  // const { estimationsInfos, estimationsPerType, estimationsInfosPerType } = getEstimationsInfosForAdmin(estimations);
  const estimationsTypes = ESTIMATION_TYPES.filter((type) => estimations.find((e) => e.information.carbonFootprintType === type.name));

  return (
    <>
      <Helmet>
        <title>Seco2 Stats</title>
      </Helmet>
      <div className="w-full">
        <PrintButton estimationsTypes={estimationsTypes} />
        <section className="w-full bg-white p-8 mb-4">
          <GeneralSection estimations={estimations} />
        </section>
        {estimationsTypes.map((type, i) => {
          const e = estimations.filter((e) => e.information.carbonFootprintType === type.name);
          return (
            <section key={i} id={`section-${i}`} className="w-full bg-white p-8 mb-4">
              <EstimationsSection estimations={e} type={type} />
            </section>
          );
        })}
      </div>
    </>
  );
};

export default Stats;

const PrintButton = ({ estimationsTypes }) => {
  const [pdfExporting, setPdfExporting] = useState(false);

  const handlePdfExport = async () => {
    // {/* @Jani TODO -> to test and adapt */}
    setPdfExporting(true);

    const doc = new jsPDF("p", "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const options = {
      scale: 4, // You may adjust this value to control the quality and size of the captured content
      useCORS: true, // Enable this if you need to capture content from external domains
    };

    let top = 0;
    const padding = 10;

    let arr = [];
    estimationsTypes.map((_, index) => {
      arr.push(`#section-${index} #estimation-first`);
      arr.push(`#section-${index} #table`);
      arr.push(`#section-${index} #chart`);
      arr.push(`#section-${index} #results`);
      arr.push(`#section-${index} #treemap`);
    });
    const sections = ["#general", "#general-table", ...arr];

    const elemsToHide = document.querySelectorAll(".to_not_print");
    const elemsToDisplay = document.querySelectorAll(".to_print");

    for (const elem of elemsToHide) {
      elem.style["display"] = "none";
    }
    for (const elem of elemsToDisplay) {
      elem.style["display"] = "block";
    }

    for (let i = 0; i < sections.length; i++) {
      const content = document.querySelector(sections[i]);
      if (!content) continue;
      const isComplexToConvert = sections[i].includes("treemap");

      let elHeight = content.offsetHeight;
      let elWidth = content.offsetWidth;
      if (elWidth > pageWidth) {
        const ratio = Math.floor((pageWidth / elWidth) * 100) / 100;
        //resize chart width and heigth proportionally
        elHeight = Math.floor(elHeight * ratio - padding * 0.4);
        elWidth = Math.floor(elWidth * ratio - padding * 2);
      }

      if (i === 0) top = 5;

      let img;
      if (isComplexToConvert) {
        img = await htmlToImage.toPng(content);
      } else {
        const canvas = await html2canvas(content, options);
        img = canvas.toDataURL("image/jpeg");
      }

      if (elHeight > pageHeight) {
        let heightLeft = elHeight;
        let position = 0;

        while (heightLeft >= 0) {
          position = heightLeft - elHeight;
          doc.addPage();
          doc.addImage(img, "PNG", padding, position + 18, elWidth, elHeight, undefined, "FAST");
          heightLeft -= pageHeight;
        }
      } else {
        if ((!sections[i].includes("auto-paging") && i > 0) || top + elHeight > pageHeight) {
          doc.addPage();
          top = 5;
        }

        doc.addImage(img, isComplexToConvert ? "PNG" : "JPEG", padding, top, elWidth, elHeight, undefined, "FAST");
      }

      top += elHeight + 10;
    }

    for (const elem of elemsToHide) {
      elem.style["display"] = "block";
    }
    for (const elem of elemsToDisplay) {
      elem.style["display"] = "none";
    }

    const synthesisTitle = "Statistiques de l'ensemble des estimations carbones";
    doc.save(synthesisTitle);
    setPdfExporting(false);
  };

  return (
    <button className="h-14 blue-button flex items-center justify-center gap-2 mb-2" onClick={handlePdfExport} disabled={pdfExporting}>
      {pdfExporting ? (
        <Loader />
      ) : (
        <>
          <BsFileEarmarkRichtext />
          Télécharger la synthèse
        </>
      )}
    </button>
  );
};

const getUnit = (value, units) => {
  if (value === 0) return { number: 0, unit: units[0] };
  const log10 = Math.floor(Math.log10(value));
  const unit = units.length > 1 ? units[Math.floor(log10 / 3)] : units[0];
  const number = value / Math.pow(10, Math.floor(log10 / 3) * 3);
  return { number, unit };
};
const getCO2Equivalence = (value) => {
  if (value > 1000) {
    return {
      value: value / 1000 / 1.77,
      unit: "",
      description: "vols A/R Paris / New York",
    };
  } else {
    return {
      value: value * 5.181,
      unit: "kms",
      description: "en voiture (moyenne)",
    };
  }
};

const GeneralSection = ({ estimations }) => {
  const impact = estimations.reduce((acc, estimation) => {
    return acc + (estimation.form?.reduce((acc, cur) => acc + cur.impact, 0) || 0);
  }, 0);
  const impactTotal = getUnit(impact, ["kgCO2eq", "tCO2eq", "ktCO2eq"]);
  const impactPerEstimation = getUnit(impact / estimations.length, ["kgCO2eq", "tCO2eq", "ktCO2eq"]);

  const budget = estimations.reduce((acc, estimation) => {
    const budget = estimation.information.budget;
    if (!budget || budget === "" || budget === "-") return acc;
    return acc + parseInt(estimation.information.budget.replaceAll(" ", ""));
  }, 0);

  const average = budget / estimations.length;
  const equivalence = getCO2Equivalence(impact);

  return (
    <>
      <div id="general">
        <h2 className="font-bold text-xl mb-6">Statistiques générales de Seco2</h2>
        <section className="grid grid-cols-4 items-center gap-5">
          <Badge icon="tournage" name={estimations.length} description={estimations.length > 1 ? "Projets complétés" : "Projet complété"} color="#cfdce3" />
          <Badge
            icon="co2"
            name={`${impactTotal.number.toLocaleString("fr", { maximumFractionDigits: 2 })} ${impactTotal.unit}`}
            description="Montant total des émissions estimées"
            color="#cfdce3"
          />

          <Badge
            icon="co2"
            name={`${impactPerEstimation.number.toLocaleString("fr", { maximumFractionDigits: 0 })} ${impactPerEstimation.unit}`}
            description="Émissions moyennes par projet"
            color="#cfdce3"
          />

          <Badge
            icon="balance"
            name={`${equivalence.value.toLocaleString("fr", { maximumFractionDigits: 0 })} ${equivalence.unit}`}
            description={equivalence.description}
            subdescription="en équivalence carbone"
            color="#cfdce3"
          />
        </section>
        <div className="flex items-center justify-center gap-8 my-4">
          <h3 className="text-lg font-semibold">Budget moyen</h3>
          <p className="text-left font-bold text-base capitalize">{average.toLocaleString("fr", { style: "currency", currency: "eur" })}</p>
        </div>

        <div className="my-4">
          <Histograms estimations={estimations} />
        </div>
      </div>
      <div className="my-4" id="general-table">
        <Table estimations={estimations} color="#cfdce3" />
      </div>
    </>
  );
};

const EstimationsSection = ({ estimations, type }) => {
  const impact = estimations.reduce((acc, estimation) => {
    return acc + (estimation.form?.reduce((acc, cur) => acc + cur.impact, 0) || 0);
  }, 0);
  const impactTotal = getUnit(impact, ["kgCO2eq", "tCO2eq", "ktCO2eq"]);
  const impactPerEstimation = getUnit(impact / estimations.length, ["kgCO2eq", "tCO2eq", "ktCO2eq"]);

  const budget = estimations.reduce((acc, estimation) => {
    const budget = estimation.information.budget;
    if (!budget || budget === "" || budget === "-") return acc;
    return acc + parseInt(estimation.information.budget.replaceAll(" ", ""));
  }, 0);

  const average = budget / estimations.length;
  const equivalence = getCO2Equivalence(impact);

  return (
    <>
      <div id="estimation-first">
        <h2 className="font-bold text-xl mb-6">Statistiques des estimations {type.label}</h2>
        <section className="grid grid-cols-4 items-center gap-5">
          <Badge icon="tournage" name={estimations.length} description={estimations.length > 1 ? "Projets complétés" : "Projet complété"} color="#cfdce3" />
          <Badge
            icon="co2"
            name={`${impactTotal.number.toLocaleString("fr", { maximumFractionDigits: 2 })} ${impactTotal.unit}`}
            description="Montant total des émissions estimées"
            color="#cfdce3"
          />

          <Badge
            icon="co2"
            name={`${impactPerEstimation.number.toLocaleString("fr", { maximumFractionDigits: 0 })} ${impactPerEstimation.unit}`}
            description="Émissions moyennes par projet"
            color="#cfdce3"
          />

          <Badge
            icon="balance"
            name={`${equivalence.value.toLocaleString("fr", { maximumFractionDigits: 0 })} ${equivalence.unit}`}
            description={equivalence.description}
            subdescription="en équivalence carbone"
            color="#cfdce3"
          />
        </section>
        <div className="flex items-center justify-center gap-8 my-4">
          <h3 className="text-lg font-semibold">Budget moyen</h3>
          <p className="text-left font-bold text-base capitalize">{average.toLocaleString("fr", { style: "currency", currency: "eur" })}</p>
        </div>
        <div className="my-4" id="histogram">
          <Histograms estimations={estimations} />
        </div>
      </div>
      <div className="my-4" id="table">
        <Table estimations={estimations} color="#cfdce3" />
      </div>
      <div className="my-4" id="chart">
        <Chart estimations={estimations} />
      </div>
      <div className="my-4" id="treemap-section">
        {/* @Jani TODO -> Adapt data maybe (I haven't tried) */}
        <TreemapSection estimations={estimations} />
      </div>
    </>
  );
};

const TreemapSection = ({ estimations }) => {
  const data = [];

  for (let estimation of estimations) {
    for (let form of estimation.form) {
      data.push(form.subCategories || []);
    }
  }

  return (
    <>
      <div id="results" className={`w-full padding_for_print`}>
        <p className="font-bold text-base leading-5 w-full flex items-center border-b border-blue-border pb-4 my-6">Répartition des émissions</p>
        <p className="font-normal text-xs leading-4 mb-5 to_print">Classement des catégories par niveau d'émissions, par ordre décroissant.</p>
        <LegendBoxForTreemap data={data} />
      </div>
      <Treemap data={data} />
    </>
  );
};

const LegendBoxForTreemap = ({ data }) => {
  let finalData = [];
  data.forEach((subCategories = []) => {
    subCategories.forEach((subCat) => finalData.push(subCat));
  });
  finalData.sort((a, b) => b.impact - a.impact);
  const indexToSplit = Math.round(finalData.length / 2);
  const dataTable1 = finalData.slice(0, indexToSplit);
  const dataTable2 = finalData.slice(indexToSplit + 1);

  const TreemapTable = ({ data }) => {
    return (
      <table>
        <thead>
          <tr>
            <th colSpan="2">Catégorie</th>
            <th>Émissions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((category, i) => (
            <tr key={i}>
              <td>
                <div style={{ backgroundColor: category.color }}></div>
              </td>
              <td className="font-normal text-xs leading-4">{category.name}</td>
              <td className="font-normal text-xs leading-4 whitespace-nowrap">{`${formatNumber(category.impact)} kgCO2eq`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div id="treemap_legend_wrapper" className="to_print">
      <h2 className="font-bold text-2xl">Répartition des émissions</h2>
      <div className="results_legend_and_table treemap_legend">
        <TreemapTable data={dataTable1} />
        <TreemapTable data={dataTable2} />
      </div>
    </div>
  );
};

const Badge = ({ icon, name, description, subdescription, color }) => {
  return (
    <div className="flex flex-col items-center p-4 h-full" style={{ backgroundColor: color }}>
      <div>
        <img src={`/images/icons/${icon}.svg`} alt={name} className="row-span-2 h-12" />
      </div>
      <p className="font-bold text-center py-2 text-lg">{name}</p>
      <p className="text-center">{description}</p>
      {subdescription && <span className="text-center text-xs leading-3 italic py-px px-3 mt-1">{subdescription}</span>}
    </div>
  );
};

// {/* @Jani TODO --> histogram not working, use the ones of chartjs */}
const Histograms = ({ estimations, color }) => {
  const stats = {
    impacts: [],
    amounts: [],
  };
  estimations
    .filter((estimation) => estimation.validated)
    .forEach((estimation) => {
      const impact = estimation.form?.reduce((acc, cur) => acc + cur.impact, 0) || 0;
      const amount = estimation.form?.reduce((acc, cur) => acc + cur.amount, 0) || 0;
      stats.impacts.push(impact);
      stats.amounts.push(amount);
    });

  return (
    <div className="w-full">
      <p className="font-bold border-b border-blue-border pb-4 my-6">Distribution des émissions et budgets</p>
      {estimations.filter((estimation) => estimation.validated).length ? (
        <div className="w-full grid grid-cols-2 gap-5">
          <div className={`w-full h-full bg-white rounded-sm grid grid-row-[200px_32px] gap-4 place-items-center`}>
            <div className="w-full h-full">
              <DistributionHistogram data={stats.impacts} unit="tCO2" color={color} title={"Émissions (tCO2)"} />
            </div>
          </div>
          <div className={`w-full h-full bg-white rounded-sm grid grid-row-[200px_32px] gap-4 place-items-center`}>
            <div className="w-full h-full">
              <DistributionHistogram data={stats.amounts} unit="k€" color={color} title={"Budgets (k€)"} />
              <DistributionHistogram data={stats.impacts} unit="tCO2" color={color} title={"Émissions (tCO2)"} />
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center">Aucune estimation validée</p>
      )}
    </div>
  );
};

const Table = ({ estimations, color }) => {
  const table = estimations.map((estimation, i) => {
    const impact = estimation.form?.reduce((acc, cur) => acc + cur.impact, 0) || 0;
    const amount = estimation.form?.reduce((acc, cur) => acc + cur.amount, 0) || 0;
    const emissions = Math.round((impact / 1000) * 10) / 10;
    const budget = Math.round((amount / 1000) * 10) / 10;
    return {
      id: i + 1,
      name: estimation.information.title,
      type: estimation.information.carbonFootprintType,
      emissions: emissions,
      budget: budget,
    };
  });

  return (
    <div className="w-full">
      <p className="font-bold border-b border-blue-border pb-4 my-6">Liste des projets</p>
      <div className="w-full">
        <table className="w-full table-auto">
          <thead>
            <tr style={{ backgroundColor: color }}>
              <th className="text-center font-semibold py-2">Projet</th>
              <th className="text-center font-semibold">Type de projet</th>
              <th className="text-center font-semibold">Émissions (tCO2eq)</th>
              <th className="text-center font-semibold">Budget (k€)</th>
            </tr>
          </thead>
          <tbody>
            {table.map((row) => (
              <tr key={row.id}>
                <td className="text-center py-1">{row.name}</td>
                <td className="text-center">{row.type}</td>
                <td className="text-center">{row.emissions.toLocaleString("fr")}</td>
                <td className="text-center">{(row.budget || 0).toLocaleString("fr", { minimumFractionDigits: 2 })} k€</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// {/* @Jani TODO (if you have time)--> clean */}
const getAverageForm = (forms) => {
  const averageForm = [];

  for (let form of forms) {
    for (let category of form) {
      let catIndex = averageForm.findIndex((avCategory) => avCategory.name === category.name);
      if (catIndex === -1) {
        averageForm.push({ name: category.name, color: category.color, impact: category.impact, subCategories: [] });
        catIndex = averageForm.length - 1;
      } else {
        averageForm[catIndex].impact += category.impact;
      }

      category.subCategories?.map((subCategory) => {
        const subCatIndex = averageForm[catIndex].subCategories.findIndex((avSubCat) => avSubCat.name === subCategory.name);
        if (subCatIndex === -1) {
          averageForm[catIndex].subCategories.push({ name: subCategory.name, color: subCategory.color, impact: subCategory.impact });
        } else {
          averageForm[catIndex].subCategories[subCatIndex].impact += subCategory.impact;
        }
      });
    }
  }

  averageForm.map((category) => {
    category.impact = category.impact / forms.length;
    category.subCategories.map((subCategory) => {
      subCategory.impact = subCategory.impact / forms.length;
      return subCategory;
    });
    return category;
  });
  return averageForm;
};

const Chart = ({ className, title, estimations }) => {
  const forms = estimations.filter((estimation) => estimation.validated).map((estimation) => estimation.form);
  const averages = getAverageForm(forms);

  const buildDonutData = (categories) => {
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };
    categories.forEach((category) => {
      data.labels.push(category.name);
      data.datasets[0].data.push(category.impact);
      data.datasets[0].backgroundColor.push(category.color);
      data.datasets[0].hoverBackgroundColor.push(category.color);
    });
    return data;
  };

  const table = [];

  for (let estimation of estimations) {
    estimation.form.forEach((category) => {
      const catIndex = table.findIndex((cat) => cat.name === category.name);
      if (catIndex === -1) {
        table.push({ name: category.name, color: category.color, impact: category.impact });
      } else {
        table[catIndex].impact += category.impact;
      }
    });
  }

  return (
    <div className="my-10 flex">
      <div className="w-[40%]">
        <div className="flex items-center mb-4">
          <h3 className="w-[60%] font-semibold">Catégorie</h3>
          <h3 className="flex-1 font-semibold text-right">Émissions</h3>
        </div>

        {/* @Jani TODO --> adapt table to have the label */}
        {table
          .filter((category) => category.impact > 0)
          .sort((a, b) => b.impact - a.impact)
          .map((category, i) => (
            <div className="flex items-center mb-3" key={i}>
              <div className="w-[60%] flex items-center">
                <div className="w-4 h-4 mr-1" style={{ backgroundColor: category.color }} />
                <span className="flex-1 font-normal text-sm truncate">{category.name}</span>
              </div>
              <span className="flex-1 font-normal text-sm text-right">{category.impact.toLocaleString("fr", { maximumFractionDigits: 2 })} kgCO2eq</span>
            </div>
          ))}
      </div>

      <div className="flex-1 flex flex-col mb-6 items-center justify-center gap-5">
        <div className="p-6 h-96">
          <Doughnut
            data={buildDonutData(averages)}
            options={{
              plugins: { legend: { display: false } },
            }}
          />
        </div>
        <p className="font-normal text-center text-base">Répartition des émissions par postes du devis</p>
      </div>
    </div>
  );
};
